<template>
  <div>
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="p-0 mb-2">
                 <label class="vs-input--label">Selecione o cliente:</label>
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="clienteSelecionado"                                                      
                    @input="fetchTabela"
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
            </vx-card>
        </vs-col>
    </vs-row> 
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card class="p-0 mb-2" v-if="clienteSelecionado  && tabelaPreco">
        <div class="vx-row mb-4 items-center" v-if="clienteSelecionado">        
            <div class="vx-col w-full"> 
                <h6 class="mb-2 text-success"> Informe as Placas </h6>
                <vs-input v-model="formularioPedido.placa" class="w-full" />
            </div>
        </div>    
        <vs-divider color="success" position="center">
            <h6 class="mb-2 text-success"> Dados do Formulário </h6>
        </vs-divider>        
        <div class="vx-row mb-4 items-center">        
            <div class="vx-col w-1/4 flex items-center"> 
                <label class="vs-input--label" >Contrato</label> 
                <v-select                                                       
                    :reduce="m => m.data"                
                    :options="CONSTANTE_TIPO_CONTRATO_VEICULO" 
                    v-model="formularioPedido.tipoContrato"                      
                    class="w-full vs-input--label">
                </v-select> 
            </div>
            <div class="vx-col w-1/4 flex items-center"> 
                <label class="vs-input--label w-full" >Prazo (meses)</label> 
                <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="formularioPedido.prazoContratoVeiculoEmMeses" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0' }]"></vue-autonumeric>
            </div>
            <div class="vx-col w-1/4 flex items-center" v-if="!formularioPedido.localizadorSatelitalBkp">    
                <label class="vs-input--label w-full" >Mensalidade</label> 
                <vue-autonumeric v-if="!formularioPedido.localizadorSatelital" class="vs-inputx vs-input--input normal hasValue w-full"  v-model="formularioPedido.mensalidadeGSM" :options="configurarNumerico2Digitos"></vue-autonumeric>
            </div>
            <div class="vx-col w-1/4 flex items-center" v-if="!formularioPedido.localizadorSatelitalBkp">                
                <vs-checkbox v-model="formularioPedido.localizadorSatelital" class="w-full" @change="setarPrazoContrato"><small>Módulo principal satelital</small></vs-checkbox>
                <vue-autonumeric v-if="formularioPedido.localizadorSatelital" class="vs-inputx vs-input--input normal hasValue w-full"  v-model="formularioPedido.mensalidadeSAT" :options="configurarNumerico2Digitos"></vue-autonumeric>
            </div>
        </div>   
        <vs-divider/>
        <div class="vx-row mt-2 mb-4 items-center">
            <div class="vx-col w-1/3 flex items-center">    
                <vs-checkbox v-model="formularioPedido.possuiBKP" class="w-full" @change="setarPrazoContrato"><small>Possui Módulo de Bkp</small></vs-checkbox>
                <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" v-if="formularioPedido.possuiBKP && !formularioPedido.localizadorSatelitalBkp" v-model="formularioPedido.mensalidadeBKP" :options="configurarNumerico2Digitos"></vue-autonumeric>                        
            </div>
            <div class="vx-col w-1/3 flex items-center">    
                <vs-checkbox v-model="formularioPedido.localizadorSatelitalBkp" class="w-full" v-if="formularioPedido.possuiBKP" @change="setarPrazoContrato"><small>Backup é satelital</small></vs-checkbox>
                <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" v-if="formularioPedido.possuiBKP && formularioPedido.localizadorSatelitalBkp" v-model="formularioPedido.mensalidadeSATeGSM" :options="configurarNumerico2Digitos"></vue-autonumeric>            
            </div>  
             <div class="vx-col w-1/6">    
                <vs-checkbox v-model="formularioPedido.possuiBloqueio" @change="setarValorInstalacao"  class="w-full"><small>Bloqueio ?</small></vs-checkbox>
            </div>
            <div class="vx-col w-1/6">    
                <vs-checkbox v-model="formularioPedido.possuiBotaoPanico" @change="setarValorInstalacao"  class="w-full"><small>Botão de Pânico ?</small></vs-checkbox>
            </div>     
            
        </div>           
        <vs-divider/>
        <div class="vx-row mt-2">
            <div class="vx-col w-1/2 flex  items-center">    
                <vs-checkbox v-model="formularioPedido.possuiSaida2" @change="setarValorInstalacao"  class="w-full"><small>Saída 2 ?</small></vs-checkbox>
                <vs-input v-if="formularioPedido.possuiSaida2" label="Atuador Saida 2"  v-model="formularioPedido.atuadorSaida2" class="w-full mr-1" />
                <vue-autonumeric v-if="formularioPedido.possuiSaida2" class="vs-inputx vs-input--input normal hasValue w-full mt-6"  v-model="formularioPedido.valorIncrementoAtuador2" :options="configurarNumerico2Digitos"></vue-autonumeric>            
            </div>
            <div class="vx-col w-1/2 flex  items-center">    
                <vs-checkbox v-model="formularioPedido.possuiSaida3" @change="setarValorInstalacao" class="w-full"><small>Saída 3 ?</small></vs-checkbox>
                <vs-input v-if="formularioPedido.possuiSaida3" label="Atuador Saida 3"  v-model="formularioPedido.atuadorSaida3" class="w-full mr-1" />
                <vue-autonumeric v-if="formularioPedido.possuiSaida3" class="vs-inputx vs-input--input normal hasValue w-full mt-6"  v-model="formularioPedido.valorIncrementoAtuador3" :options="configurarNumerico2Digitos"></vue-autonumeric>
            </div>       
        </div>
        <vs-divider/>
        <div class="vx-row mt-2">
            <div class="vx-col w-1/2 flex"> 
                <div class="w-full mr-2">
                    <label class="vs-input--label">Entrada 1:</label>   
                    <v-select                                               
                        :options="CONSTANTE_TIPOS_AVISO_AUX" 
                        v-model="formularioPedido.avisoAux0"                                        
                        class="vs-input--label">
                    </v-select>
                </div>
                <vs-input v-if="formularioPedido.avisoAux0 && formularioPedido.avisoAux0.data === 'outro'" v-model="formularioPedido.avisoAux0Outro" class="w-full mr-2 mt-5" />
                <vue-autonumeric v-if="formularioPedido.avisoAux0" :placeholder="'Valor mensal'" class="vs-inputx vs-input--input normal hasvalue w-full mt-5"  v-model="formularioPedido.valorIncrementoSensor1" :options="configurarnumerico2digitos"></vue-autonumeric>
            </div>
            <div class="vx-col w-1/2 flex"> 
                <div class="w-full mr-2">
                    <label class="vs-input--label">Entrada 2:</label>   
                    <v-select                                               
                        :options="CONSTANTE_TIPOS_AVISO_AUX" 
                        v-model="formularioPedido.avisoAux1"                                           
                        class="vs-input--label">
                    </v-select>
                </div>
                <vs-input v-if="formularioPedido.avisoAux1 && formularioPedido.avisoAux1.data === 'outro'" v-model="formularioPedido.avisoAux1Outro" class="w-full mr-2 mt-5" />
                <vue-autonumeric v-if="formularioPedido.avisoAux1" :placeholder="'Valor mensal'" class="vs-inputx vs-input--input normal hasvalue w-full mt-5"  v-model="formularioPedido.valorIncrementoSensor2" :options="configurarnumerico2digitos"></vue-autonumeric>
            </div>
        </div>
        <div class="vx-row mt-2">
            <div class="vx-col w-1/2 flex"> 
                <div class="w-full mr-2">
                    <label class="vs-input--label">Entrada 3:</label>   
                    <v-select                                               
                        :options="CONSTANTE_TIPOS_AVISO_AUX" 
                        v-model="formularioPedido.avisoAux2"                                           
                        class="vs-input--label">
                    </v-select>
                </div>
                <vs-input v-if="formularioPedido.avisoAux2 && formularioPedido.avisoAux2.data === 'outro'" v-model="formularioPedido.avisoAux2Outro" class="w-full mr-2 mt-5" />
                <vue-autonumeric v-if="formularioPedido.avisoAux2" :placeholder="'Valor mensal'" class="vs-inputx vs-input--input normal hasvalue w-full mt-5"  v-model="formularioPedido.valorIncrementoSensor3" :options="configurarnumerico2digitos"></vue-autonumeric>
            </div>      
            <div class="vx-col w-1/2 flex">    
                <vs-input label="Sensor RS232" v-model="formularioPedido.sensorRS232" class="w-full mr-1" />
                <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full mt-6" :placeholder="'Valor mensal'"  v-model="formularioPedido.valorIncrementoSensorRS232" :options="configurarNumerico2Digitos"></vue-autonumeric>
            </div>   
        </div>
        <vs-divider/>
        <div class="vx-row mt-2 items-center">
            <div class="vx-col w-1/4 flex">    
                <vs-checkbox v-model="formularioPedido.possuiTeclado" @change="setarValorInstalacao" class="w-full"><small>Teclado ?</small></vs-checkbox>
                <vue-autonumeric v-if="formularioPedido.possuiTeclado" :placeholder="'Valor mensal'" class="vs-inputx vs-input--input normal hasValue w-full"  v-model="formularioPedido.valorIncrementoTeclado" :options="configurarNumerico2Digitos"></vue-autonumeric>
            </div>
            <div class="vx-col w-1/4 flex">    
                <vs-checkbox v-model="formularioPedido.possuiTelemetria" @change="setarValorInstalacao" class="w-full"><small>Telemetria ?</small></vs-checkbox>
                <vue-autonumeric v-if="formularioPedido.possuiTelemetria" :placeholder="'Valor mensal'" class="vs-inputx vs-input--input normal hasValue w-full"  v-model="formularioPedido.valorIncrementoLeitorCAN" :options="configurarNumerico2Digitos"></vue-autonumeric>
            </div>
            <div class="vx-col w-1/4 flex">    
                <vs-checkbox v-model="formularioPedido.possuiIdentificadorMotorista" @change="setarValorInstalacao" class="w-full"><small>Indet. Motorista ?</small></vs-checkbox>
                <vue-autonumeric v-if="formularioPedido.possuiIdentificadorMotorista" :placeholder="'Valor mensal'" class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeIdentificadorMotorista" :options="configurarNumerico2Digitos"></vue-autonumeric>
            </div>
            <div class="vx-col w-1/4 flex">    
                <vs-checkbox v-model="formularioPedido.possuiChipMultioperadora" class="w-full"><small>Chip Multioperadora</small></vs-checkbox>
                <vue-autonumeric v-if="formularioPedido.possuiChipMultioperadora" :placeholder="'Valor mensal'" class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeChipMultioperadora" :options="configurarNumerico2Digitos"></vue-autonumeric>
            </div>
        </div>    
        <vs-divider/>
        <div class="vx-row mt-2">
            <div class="vx-col w-1/4 flex  items-center">    
                <label class="vs-input--label w-full">Valor da Instalação</label> 
                <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="formularioPedido.valorInstalacao" :options="configurarNumerico2Digitos"></vue-autonumeric>
            </div>
            <div class="vx-col w-1/4 flex items-center">    
                <label class="vs-input--label w-full">Valor da Desinstalação</label> 
                <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="formularioPedido.valorDesinstalacao" :options="configurarNumerico2Digitos"></vue-autonumeric>
            </div>

            <div class="vx-col w-1/2 flex items-center">                 
                <vs-textarea v-model="formularioPedido.observacao" class="w-full" placeholder="Observação" />
            </div>
        </div>   
    </vx-card>

    <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-printer" @click="gerarRelatorio()" v-if="clienteSelecionado">GERAR FORMULÁRIO</vs-button>
    
    <vs-popup fullscreen title="Formulário Pedido" :active.sync="popUpImpressao.exibir" v-if="popUpImpressao.exibir">
        <ViewRelatorio v-if="dadosPDF" :sourcePDF="dadosPDF" class="mt-2"
            @exportarPDF="exportar('PDF')" @exportarXLS="exportar('XLS')" 
            @exportarTXT="exportar('TXT')" @exportarRTF="exportar('RTF')" />   
    </vs-popup>
  </div>
</template>

<script>
import axios from "@/axios.js"
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import { CONSTANTE_TIPO_CONTRATO_VEICULO } from '@/constantesComboBox.js'
import ViewRelatorio from '@/views/components/ViewRelatorioJasper'


export default {   
    components: {      
        ViewRelatorio,
        VueAutonumeric    
    },   
    created() {
      this.CONSTANTE_TIPO_CONTRATO_VEICULO = CONSTANTE_TIPO_CONTRATO_VEICULO;
      this.CONSTANTE_TIPOS_AVISO_AUX =  [      
            {label:"Abertura Capô", data:"AberturaCapo"},
            {label:"Abertura Porta-Malas", data:"AberturaPortaMalas"},
            {label:"Abertura Porta", data:"AberturaPorta"},
            {label:"Abertura Cofre", data:"AberturaCofre"},
            {label:"Acionamento Engate", data:"AcionamentoEngate"},
            {label:"Acionamento Caçamba", data:"AcionamentoCacamba"},
            {label:"Acionamento Embreagem", data:"AcionamentoEmbreagem"},
            {label:"Acionamento do Botão de Emergência do Passageiro", data:"AcionamentoEmergenciaPassageiro"},
            {label:"Acionamento do Botão de Emergência do Banco Traseiro", data:"AcionamentoEmergenciaBancoTraseiro"},
            {label:"Câmara Fria", data:"CamaraFriaLigada"},
            {label:"Limpador de Para-brisa", data:"LimpadorParabrisasLigado"},
            {label:"Tampa de Combustível", data:"FechamentoTanqueCombustivel"},
            {label:"Tampa de Combustível Tanque 1", data:"FechamentoTanque1Combustivel"},
            {label:"Tampa de Combustível Tanque 2", data:"FechamentoTanque2Combustivel"},
            {label:"Outro", data:'outro'}
        ];
    },
    data() {
        return {     
            formularioPedido: {
                tipoContrato: null, valor: null, valorBkp: null, possuiBloqueio: false, possuiBotaoPanico: false, 
                possuiSaida2: false, possuiSaida3: false, atuadorSaida2: null, atuadorSaida3: null, 
                avisoAux0: null, avisoAux1: null, avisoAux2: null, avisoAux0Outro: null, avisoAux1Outro: null, avisoAux2Outro: null,
                possuiTelemetria: false, possuiIdentificadorMotorista: false, visita_verificacao: null, valorIncrementoTeclado: null,
                instalacao: null, desinstalacao: null, bloqueio: null, sensor_rpm: null, odometro: null, sensor_simples: null, sensor_tanque: null, botao_panico: null,                 
                mensalidadeGSM: null, mensalidadeBKP: null, mensalidadeSAT: null, mensalidadeCelular: null, mensalidadeSATeGSM: null, 
                valorIncrementoLeitorCAN: null, valorIncrementoIdentificacaoMotorista: null,  valorIncrementoSensor1: null, valorIncrementoSensor2: null, valorIncrementoSensor3: null, 
                valorIncrementoAtuador2: null, valorIncrementoAtuador3: null, valorRessarcimento: null, possuiBKP: false, possuiTeclado: false, prazoContratoVeiculoEmMeses: null, 
                localizadorSatelital: false, localizadorSatelitalBkp: false, possuiChipMultioperadora: false, sensorRS232: null, valorIncrementoSensorRS232: null, valorInstalacao: null, 
                valorDesinstalacao: null, observacao: null
            },     
            tabelaPreco: {
                instalacao: 165,
                desinstalacao: 75,
                bloqueio: 75,
                sensorRpm: 150,
                odometro: 150,
                sensorSimples: 100,
                sensorTanque: 350,
                botaoPanico: 45,
                visitaVerificacao: 50,
                mensalidadeGSM: 80,
                mensalidadeSAT: 125,
                mensalidadeSMS: 0,
                franquiaSMS: 0,
                mensalidadeBKP: 85,
                mensalidadeSATeGSM: 195,
                mensalidadeIdentificadorMotorista: 10,
                mensalidadeAtuadorSaida1a3: 5,
                mensalidadeRS232: 15,
                mensalidadeTeclado: 10,
                mensalidadeTelemetria: 65,
                mensalidadeSensorEntradas1a3: 5,
                mensalidadeMoto: 50,
                mensalidadeChipMultioperadora: 10,
                mensalidadeCelular: 5,
                instalacaoRastreadorBloqueio: 165,
                instalacaoRastreadorBloqueioPanico: 165,
                instalacaoIdentificadorMotorista: 215,
                instalacaoTelemetria: 110,
                instalacaoRS232: 100,
                instalacaoTravas: 850,
                instalacaoAtuadorSimples: 100,
                instalacaoSAT: 275,
                instalacaoGSMeSAT: 326,
                fidelizacaoGSM: 24,
                fidelizacaoSAT: 36,
                fidelizacaoGSMeSAT: 36,
            },              
            listaClientes: [], 
            clienteSelecionado: null,                    
            errorsValidacao: [],   
            listaVeiculosSelecionados: [],   
            dadosPDF: null,              
            popUpImpressao: {
                exibir: false,          
            },
            popUpSelecao : {
                popupActive: false,
                exibirMensagem: false,
                mensagem: null          
            },
            configurarNumerico2Digitos: [{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }],
            configurarNumerico0Digitos: [{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0' }],
            publicPath: process.env.BASE_URL
        }
    },
    methods: {    
        setarValorInstalacao() {
            let calculoValorInstalacao = 0;
            if (this.formularioPedido.possuiBKP) {
                if (this.formularioPedido.localizadorSatelitalBkp) {
                    calculoValorInstalacao = parseFloat(this.tabelaPreco.instalacaoGSMeSAT);
                } else {
                    if (this.possuiBloqueio && this.possuiBotaoPanico) {
                        calculoValorInstalacao = parseFloat(this.tabelaPreco.instalacaoRastreadorBloqueioPanico)*2;
                    } else if (this.possuiBloqueio && !this.possuiBotaoPanico) {
                        calculoValorInstalacao = parseFloat(this.tabelaPreco.instalacaoRastreadorBloqueio)*2;
                    } else {
                        calculoValorInstalacao = parseFloat(this.tabelaPreco.instalacao)*2;
                    }
                }
            } else if (this.formularioPedido.localizadorSatelital) {
                calculoValorInstalacao = parseFloat(this.tabelaPreco.instalacaoSAT);
            }  else {
                if (this.possuiBloqueio && this.possuiBotaoPanico) {
                    calculoValorInstalacao = parseFloat(this.tabelaPreco.instalacaoRastreadorBloqueioPanico);
                } else if (this.possuiBloqueio && !this.possuiBotaoPanico) {
                    calculoValorInstalacao = parseFloat(this.tabelaPreco.instalacaoRastreadorBloqueio);
                } else {
                    calculoValorInstalacao = parseFloat(this.tabelaPreco.instalacao);
                }
            }  
            
            if (this.formularioPedido.possuiIdentificadorMotorista) {
                calculoValorInstalacao = calculoValorInstalacao + parseFloat(this.tabelaPreco.instalacaoIdentificadorMotorista);
            }
            if (this.formularioPedido.possuiTelemetria) {
                calculoValorInstalacao = calculoValorInstalacao + parseFloat(this.tabelaPreco.instalacaoTelemetria);
            }            
            if (this.formularioPedido.sensorRS232) {
                calculoValorInstalacao = calculoValorInstalacao + parseFloat(this.tabelaPreco.instalacaoRS232);
            }
            if (this.formularioPedido.avisoAux0) {
                calculoValorInstalacao = calculoValorInstalacao + parseFloat(this.tabelaPreco.sensorSimples);
            }
            if (this.formularioPedido.avisoAux1) {
                calculoValorInstalacao = calculoValorInstalacao + parseFloat(this.tabelaPreco.sensorSimples);
            }
            if (this.formularioPedido.avisoAux2) {
                calculoValorInstalacao = calculoValorInstalacao + parseFloat(this.tabelaPreco.sensorSimples);
            }
            if (this.formularioPedido.possuiSaida2) {
                calculoValorInstalacao = calculoValorInstalacao + parseFloat(this.tabelaPreco.instalacaoAtuadorSimples);
            }
            if (this.formularioPedido.possuiSaida3) {
                calculoValorInstalacao = calculoValorInstalacao + parseFloat(this.tabelaPreco.instalacaoAtuadorSimples);
            }

            this.formularioPedido.valorInstalacao = parseFloat(calculoValorInstalacao);            
        },
        setarPrazoContrato() {
          if (this.formularioPedido.possuiBKP) {
              if (this.formularioPedido.localizadorSatelitalBkp) {
                  this.formularioPedido.prazoContratoVeiculoEmMeses = this.tabelaPreco.fidelizacaoGSMeSAT;
              } else {
                  this.formularioPedido.prazoContratoVeiculoEmMeses = this.tabelaPreco.fidelizacaoGSM;
              }
          } else if (this.formularioPedido.localizadorSatelital) {
              this.formularioPedido.prazoContratoVeiculoEmMeses = this.tabelaPreco.fidelizacaoSAT;
          }  else {
              this.formularioPedido.prazoContratoVeiculoEmMeses = this.tabelaPreco.fidelizacaoGSM;
          }  
          this.setarValorInstalacao();
        },
        imprimir(tr) {
            this.listaVeiculosSelecionados = [];
            this.listaVeiculosSelecionados.push(tr);
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja imprimir o formulário de pedido do veículo '
                 + tr.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.gerarRelatorio
            });
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },        
        fetchListaPreCadastros() {     
            this.$vs.loading() 
            const params = new URLSearchParams();

            axios.post("/rest/PreCadastro/Listar", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {                    
                    if (response.data.Resultado.quantidade > 1) {
                        this.listaClientes = response.data.Resultado.lista.item;
                    } else if (response.data.Resultado.quantidade === 1) {
                        this.listaClientes = [];
                        this.listaClientes.push(response.data.Resultado.lista.item);
                    } else {
                        this.listaClientes = [];
                    }                              
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        },      
        exportar(pTipoFormato) {
            if (!this.formularioValido()) {
                return;
            }
            this.$vs.loading();

            const params = new URLSearchParams();            
            params.append('outputFormat', 'JSON');          
            params.append('nomeRelatorio', "FormularioPedidoManualPreCadastro");
            params.append('exportarTipo', 'PDF');
            params.append('placa', this.formularioPedido.placa);
            params.append('pIdCliente', this.clienteSelecionado.id);
            params.append('tipoContrato', this.formularioPedido.tipoContrato);
            params.append('prazoContratoVeiculoEmMeses', this.formularioPedido.prazoContratoVeiculoEmMeses);
            
            if (this.formularioPedido.localizadorSatelital) {
                params.append('valor', this.formularioPedido.mensalidadeSAT);
            } else {
                params.append('valor', this.formularioPedido.mensalidadeGSM);
            }
            params.append('possuiBKP', this.formularioPedido.possuiBKP);
            if (this.formularioPedido.possuiBKP) {
                if (this.formularioPedido.localizadorSatelitalBkp) {
                    params.append('valorBkp', (this.formularioPedido.mensalidadeSATeGSM - this.formularioPedido.mensalidadeGSM));
                } else {
                    params.append('valorBkp', this.formularioPedido.mensalidadeBKP);
                }
                params.append('localizadorSatelitalBkp', this.formularioPedido.localizadorSatelitalBkp);
            } else {
                params.append('valorBkp', 0);
                params.append('localizadorSatelitalBkp', false);
            }
            params.append('possuiBloqueio', this.formularioPedido.possuiBloqueio);
            params.append('possuiBotaoPanico', this.formularioPedido.possuiBotaoPanico);
            
            if (this.formularioPedido.avisoAux0) {
                if (this.formularioPedido.avisoAux0.data === 'outro') {
                    params.append('avisoAux0', this.formularioPedido.avisoAux0Outro);                        
                } else {
                    params.append('avisoAux0', this.formularioPedido.avisoAux0.label);
                }
                params.append('valorIncrementoSensor1', this.formularioPedido.valorIncrementoSensor1);
            } else {
                params.append('valorIncrementoSensor1', 0);
            }
            
            if (this.formularioPedido.avisoAux1) {
                if (this.formularioPedido.avisoAux1.data === 'outro') {
                    params.append('avisoAux1', this.formularioPedido.avisoAux1Outro);                        
                } else {
                    params.append('avisoAux1', this.formularioPedido.avisoAux1.label);
                }
                params.append('valorIncrementoSensor2', this.formularioPedido.valorIncrementoSensor2);
            } else {
                params.append('valorIncrementoSensor2', 0);
            }
            
            if (this.formularioPedido.avisoAux2) {
                if (this.formularioPedido.avisoAux2.data === 'outro') {
                    params.append('avisoAux2', this.formularioPedido.avisoAux2Outro);                        
                } else {
                    params.append('avisoAux2', this.formularioPedido.avisoAux2.label);
                }
                params.append('valorIncrementoSensor3', this.formularioPedido.valorIncrementoSensor3);
            } else {
                params.append('valorIncrementoSensor3', 0);
            }
            
            params.append('possuiSaida2', this.formularioPedido.possuiSaida2);
            if (this.formularioPedido.possuiSaida2) {
                params.append('atuadorSaida2', this.formularioPedido.atuadorSaida2);                        
                params.append('valorIncrementoAtuador2', this.formularioPedido.valorIncrementoAtuador2);
            } else {
                params.append('valorIncrementoAtuador2', 0);
            }

            params.append('possuiSaida3', this.formularioPedido.possuiSaida3);
            if (this.formularioPedido.possuiSaida3) {
                params.append('atuadorSaida3', this.formularioPedido.atuadorSaida3);  
                params.append('valorIncrementoAtuador3', this.formularioPedido.valorIncrementoAtuador3);
            } else {
                params.append('valorIncrementoAtuador3', 0);
            }

            params.append('possuiTelemetria', this.formularioPedido.possuiTelemetria);
            if (this.formularioPedido.possuiTelemetria) {
                params.append('valorIncrementoLeitorCAN', this.formularioPedido.valorIncrementoLeitorCAN);
            } else {
                params.append('valorIncrementoLeitorCAN', 0);
            }

            params.append('possuiIdentificadorMotorista', this.formularioPedido.possuiIdentificadorMotorista);
            if (this.formularioPedido.possuiIdentificadorMotorista) {
                params.append('valorIncrementoIdentificacaoMotorista', this.tabelaPreco.mensalidadeIdentificadorMotorista);
            } else {
                params.append('valorIncrementoIdentificacaoMotorista', 0);
            }
            params.append('instalacao', this.tabelaPreco.instalacao);
            params.append('desinstalacao', this.tabelaPreco.desinstalacao);
            params.append('bloqueio', this.tabelaPreco.bloqueio);
            params.append('sensor_rpm', this.tabelaPreco.sensorRpm);
            params.append('odometro', this.tabelaPreco.odometro);
            params.append('sensor_simples', this.tabelaPreco.sensorSimples);
            params.append('sensor_tanque', this.tabelaPreco.sensorTanque);
            params.append('botao_panico', this.tabelaPreco.botaoPanico);
            params.append('visita_verificacao', this.tabelaPreco.visitaVerificacao);
            params.append('mensalidadeGSM', this.tabelaPreco.mensalidadeGSM);
            params.append('mensalidadeBKP', this.tabelaPreco.mensalidadeBKP);
            params.append('mensalidadeSAT', this.tabelaPreco.mensalidadeSAT);
            params.append('mensalidadeSATeGSM', this.tabelaPreco.mensalidadeSATeGSM);

            params.append('possuiTeclado', this.formularioPedido.possuiTeclado);
            if (this.formularioPedido.possuiTeclado) {
                params.append('valorIncrementoTeclado', this.formularioPedido.valorIncrementoTeclado);
            } else {
                params.append('valorIncrementoTeclado', 0);
            }

            if (this.formularioPedido.sensorRS232) {
                params.append('sensorRS232', this.formularioPedido.sensorRS232);
                params.append('valorIncrementoSensorRS232', this.formularioPedido.valorIncrementoSensorRS232);
            } else {
                params.append('valorIncrementoSensorRS232', 0);
            }
            
            params.append('valorRessarcimento', "550");
            params.append('localizadorSatelital', this.formularioPedido.localizadorSatelital);
            params.append('possuiChipMultioperadora', this.formularioPedido.possuiChipMultioperadora);
            if (this.formularioPedido.possuiChipMultioperadora) {
                params.append('valorIncrementoMultioperadora', this.tabelaPreco.mensalidadeChipMultioperadora);
            } else {
                params.append('valorIncrementoMultioperadora', "0");
            }
            if (this.formularioPedido.valorInstalacao) {
                params.append('valorInstalacao', this.formularioPedido.valorInstalacao);
            } else {
                params.append('valorInstalacao', 0);
            }

            if (this.formularioPedido.valorDesinstalacao) {
                params.append('valorDesinstalacao', this.formularioPedido.valorDesinstalacao);
            } else {
                params.append('valorDesinstalacao', 0);
            }

            if (this.formularioPedido.observacao) {
                params.append('observacao', this.formularioPedido.observacao);
            }

            axios.post("/RelatoriosJasper", params, {              
                        credentials: 'include', responseEncoding: 'iso88591', withCredentials: true,  responseType: 'arraybuffer' } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/login' });
                } else {                                                
                    
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'FormularioPedido - ' +  this.clienteSelecionado.nome + '.' + pTipoFormato.toLowerCase());
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - exportar - " + error); 
                this.$vs.loading.close();
            }) 

        },
        gerarRelatorio() {
            if (!this.formularioValido()) {
                return;
            }
            this.$vs.loading();

            const params = new URLSearchParams();            
            params.append('outputFormat', 'JSON');          
            params.append('nomeRelatorio', "FormularioPedidoManualPreCadastro");
            params.append('exportarTipo', 'PDF');
            params.append('placa', this.formularioPedido.placa);
            params.append('pIdCliente', this.clienteSelecionado.id);
            params.append('tipoContrato', this.formularioPedido.tipoContrato);
            params.append('prazoContratoVeiculoEmMeses', this.formularioPedido.prazoContratoVeiculoEmMeses);
            
            if (this.formularioPedido.localizadorSatelital) {
                params.append('valor', this.formularioPedido.mensalidadeSAT);
            } else {
                params.append('valor', this.formularioPedido.mensalidadeGSM);
            }
            params.append('possuiBKP', this.formularioPedido.possuiBKP);
            if (this.formularioPedido.possuiBKP) {
                if (this.formularioPedido.localizadorSatelitalBkp) {
                    params.append('valorBkp', (this.formularioPedido.mensalidadeSATeGSM - this.formularioPedido.mensalidadeGSM));
                } else {
                    params.append('valorBkp', this.formularioPedido.mensalidadeBKP);
                }
                params.append('localizadorSatelitalBkp', this.formularioPedido.localizadorSatelitalBkp);
            } else {
                params.append('valorBkp', 0);
                params.append('localizadorSatelitalBkp', false);
            }
            params.append('possuiBloqueio', this.formularioPedido.possuiBloqueio);
            params.append('possuiBotaoPanico', this.formularioPedido.possuiBotaoPanico);
            
            if (this.formularioPedido.avisoAux0) {
                if (this.formularioPedido.avisoAux0.data === 'outro') {
                    params.append('avisoAux0', this.formularioPedido.avisoAux0Outro);                        
                } else {
                    params.append('avisoAux0', this.formularioPedido.avisoAux0.label);
                }
                params.append('valorIncrementoSensor1', this.formularioPedido.valorIncrementoSensor1);
            } else {
                params.append('valorIncrementoSensor1', 0);
            }
            
            if (this.formularioPedido.avisoAux1) {
                if (this.formularioPedido.avisoAux1.data === 'outro') {
                    params.append('avisoAux1', this.formularioPedido.avisoAux1Outro);                        
                } else {
                    params.append('avisoAux1', this.formularioPedido.avisoAux1.label);
                }
                params.append('valorIncrementoSensor2', this.formularioPedido.valorIncrementoSensor2);
            } else {
                params.append('valorIncrementoSensor2', 0);
            }
            
            if (this.formularioPedido.avisoAux2) {
                if (this.formularioPedido.avisoAux2.data === 'outro') {
                    params.append('avisoAux2', this.formularioPedido.avisoAux2Outro);                        
                } else {
                    params.append('avisoAux2', this.formularioPedido.avisoAux2.label);
                }
                params.append('valorIncrementoSensor3', this.formularioPedido.valorIncrementoSensor3);
            } else {
                params.append('valorIncrementoSensor3', 0);
            }
            
            params.append('possuiSaida2', this.formularioPedido.possuiSaida2);
            if (this.formularioPedido.possuiSaida2) {
                params.append('atuadorSaida2', this.formularioPedido.atuadorSaida2);                        
                params.append('valorIncrementoAtuador2', this.formularioPedido.valorIncrementoAtuador2);
            } else {
                params.append('valorIncrementoAtuador2', 0);
            }

            params.append('possuiSaida3', this.formularioPedido.possuiSaida3);
            if (this.formularioPedido.possuiSaida3) {
                params.append('atuadorSaida3', this.formularioPedido.atuadorSaida3);  
                params.append('valorIncrementoAtuador3', this.formularioPedido.valorIncrementoAtuador3);
            } else {
                params.append('valorIncrementoAtuador3', 0);
            }

            params.append('possuiTelemetria', this.formularioPedido.possuiTelemetria);
            if (this.formularioPedido.possuiTelemetria) {
                params.append('valorIncrementoLeitorCAN', this.formularioPedido.valorIncrementoLeitorCAN);
            } else {
                params.append('valorIncrementoLeitorCAN', 0);
            }

            params.append('possuiIdentificadorMotorista', this.formularioPedido.possuiIdentificadorMotorista);
            if (this.formularioPedido.possuiIdentificadorMotorista) {
                params.append('valorIncrementoIdentificacaoMotorista', this.tabelaPreco.mensalidadeIdentificadorMotorista);
            } else {
                params.append('valorIncrementoIdentificacaoMotorista', 0);
            }
            params.append('instalacao', this.tabelaPreco.instalacao);
            params.append('desinstalacao', this.tabelaPreco.desinstalacao);
            params.append('bloqueio', this.tabelaPreco.bloqueio);
            params.append('sensor_rpm', this.tabelaPreco.sensorRpm);
            params.append('odometro', this.tabelaPreco.odometro);
            params.append('sensor_simples', this.tabelaPreco.sensorSimples);
            params.append('sensor_tanque', this.tabelaPreco.sensorTanque);
            params.append('botao_panico', this.tabelaPreco.botaoPanico);
            params.append('visita_verificacao', this.tabelaPreco.visitaVerificacao);
            params.append('mensalidadeGSM', this.tabelaPreco.mensalidadeGSM);
            params.append('mensalidadeBKP', this.tabelaPreco.mensalidadeBKP);
            params.append('mensalidadeSAT', this.tabelaPreco.mensalidadeSAT);
            params.append('mensalidadeSATeGSM', this.tabelaPreco.mensalidadeSATeGSM);

            params.append('possuiTeclado', this.formularioPedido.possuiTeclado);
            if (this.formularioPedido.possuiTeclado) {
                params.append('valorIncrementoTeclado', this.formularioPedido.valorIncrementoTeclado);
            } else {
                params.append('valorIncrementoTeclado', 0);
            }

            if (this.formularioPedido.sensorRS232) {
                params.append('sensorRS232', this.formularioPedido.sensorRS232);
                params.append('valorIncrementoSensorRS232', this.formularioPedido.valorIncrementoSensorRS232);
            } else {
                params.append('valorIncrementoSensorRS232', 0);
            }
            
            params.append('valorRessarcimento', "550");
            params.append('localizadorSatelital', this.formularioPedido.localizadorSatelital);
            params.append('possuiChipMultioperadora', this.formularioPedido.possuiChipMultioperadora);
            if (this.formularioPedido.possuiChipMultioperadora) {
                params.append('valorIncrementoMultioperadora', this.tabelaPreco.mensalidadeChipMultioperadora);
            } else {
                params.append('valorIncrementoMultioperadora', "0");
            }
            if (this.formularioPedido.valorInstalacao) {
                params.append('valorInstalacao', this.formularioPedido.valorInstalacao);
            } else {
                params.append('valorInstalacao', 0);
            }

            if (this.formularioPedido.valorDesinstalacao) {
                params.append('valorDesinstalacao', this.formularioPedido.valorDesinstalacao);
            } else {
                params.append('valorDesinstalacao', 0);
            }

            if (this.formularioPedido.observacao) {
                params.append('observacao', this.formularioPedido.observacao);
            }

            axios.post("/RelatoriosJasper", params, {              
                        credentials: 'include', responseEncoding: 'iso88591', withCredentials: true,  responseType: 'arraybuffer' } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/login' });
                } else {                                                
                    this.dadosPDF = new Uint8Array(response.data);                
                    if (!this.dadosPDF || this.dadosPDF.length < 2000) {
                        this.dadosPDF = null;          
                        this.popUpImpressao.exibir = false;          
                    }  else {                  
                        this.popUpSelecao.popupActive = false;        
                        this.popUpImpressao.exibir = true;
                    }
                }
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
                this.$vs.loading.close();
            }) 
        },
        fetchTabela() {     
            this.exibirTabela = false;
            this.tabelaPreco = null;

            if (!this.clienteSelecionado) {
                return;
            }
            
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');            
            params.append('isTabelaPreCadastro', true);
                        
            axios.post("/RecuperarTabelaPrecoCliente", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.tabelaPreco = response.data;
                    this.formularioPedido.mensalidadeGSM = this.tabelaPreco.mensalidadeGSM;
                    this.formularioPedido.mensalidadeBKP = this.tabelaPreco.mensalidadeBKP;
                    this.formularioPedido.mensalidadeSAT = this.tabelaPreco.mensalidadeSAT;
                    this.formularioPedido.mensalidadeSATeGSM = this.tabelaPreco.mensalidadeSATeGSM;

                    this.formularioPedido.valorIncrementoTeclado = this.tabelaPreco.mensalidadeTeclado;
                    this.formularioPedido.valorIncrementoLeitorCAN = this.tabelaPreco.mensalidadeTelemetria;
                    this.formularioPedido.prazoContratoVeiculoEmMeses = this.tabelaPreco.fidelizacaoGSM;
                    this.formularioPedido.valorIncrementoSensor1 = this.tabelaPreco.mensalidadeSensorEntradas1a3;
                    this.formularioPedido.valorIncrementoSensor2 = this.tabelaPreco.mensalidadeSensorEntradas1a3;
                    this.formularioPedido.valorIncrementoSensor3 = this.tabelaPreco.mensalidadeSensorEntradas1a3;
                    this.formularioPedido.valorIncrementoAtuador1 = this.tabelaPreco.mensalidadeAtuadorSaida1a3;
                    this.formularioPedido.valorIncrementoAtuador2 = this.tabelaPreco.mensalidadeAtuadorSaida1a3;
                    this.formularioPedido.valorIncrementoAtuador3 = this.tabelaPreco.mensalidadeAtuadorSaida1a3;
                    this.formularioPedido.valorInstalacao = this.tabelaPreco.instalacao;
                    this.formularioPedido.valorDesinstalacao = this.tabelaPreco.desinstalacao;
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },
        formularioValido() {
            this.$refs.errosComponent.iniciar();
            this.errorsValidacao = [];

            if (!this.formularioPedido.placa) { this.errorsValidacao.push("Informe a placa dos veículos"); }
            if (!this.formularioPedido.tipoContrato) { this.errorsValidacao.push("Informe tipo do contrato"); }
            if (!this.formularioPedido.prazoContratoVeiculoEmMeses) { this.errorsValidacao.push("Informe prazo (meses)"); }
            if (this.formularioPedido.possuiSaida2) { 
                if (!this.formularioPedido.atuadorSaida2) {
                   this.errorsValidacao.push("Informe o nome do atuador da saída 2"); 
                }
            }
            if (this.formularioPedido.possuiSaida3) { 
                if (!this.formularioPedido.atuadorSaida3) {
                   this.errorsValidacao.push("Informe o nome do atuador da saída 3"); 
                }
            }

            if (this.formularioPedido.avisoAux0 === 'outro') { 
                if (!this.formularioPedido.avisoAux0Outro) {
                   this.errorsValidacao.push("Informe o nome do sensor da entrada 1"); 
                }
            }
            if (this.formularioPedido.avisoAux1 === 'outro') { 
                if (!this.formularioPedido.avisoAux1Outro) {
                   this.errorsValidacao.push("Informe o nome do sensor da entrada 2"); 
                }
            }
            if (this.formularioPedido.avisoAux2 === 'outro') { 
                if (!this.formularioPedido.avisoAux2Outro) {
                   this.errorsValidacao.push("Informe o nome do sensor da entrada 3"); 
                }
            }

            if (!this.errorsValidacao.length) {
                return true;
            }   
        
            return false;
        }
    },
    mounted() {               
        this.fetchListaPreCadastros();
    }
   
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 200px);
      position: relative;      
    } 
</style>

<style>  
  .style-chooser .vs__dropdown-menu {
    max-height: 150px;
  }
</style>